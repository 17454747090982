import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';
import { AuthFormComponent } from '../../shared/components/auth-form/auth-form.component';
import { ProfileFlyoutComponent } from '../profile-flyout/profile-flyout.component';
import { NotificationBadgeComponent } from '../../shared/components/notification-badge/notification-badge.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';
import { EButtonActionTypes } from 'app/shared/enums/button.enums';
import { EAuthType } from 'app/auth/auth.enums';
import { ELocalStorageKey } from 'app/shared/enums/shared.enums';
import { EProfileState } from 'app/enums/profile.enums';

@Component({
  selector: 'stxt-header',
  standalone: true,
  imports: [
    SvgIconComponent,
    RouterModule,
    CommonModule,
    NotificationBadgeComponent,
    ProfileFlyoutComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  public profileSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'profile' };
  public chatSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'conversation' };
  public homeSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'home' };
  $isUser = this.authService.$isUser;
  rightIcon: ISvgConfig = { name: 'arrow_right2', fill: ESvgTypes.None };
  isUserAuth: boolean;
  showProfileFlyout: boolean;
  showDropdown: boolean = false;
  user = JSON.parse(localStorage.getItem(ELocalStorageKey.User));
  userId = localStorage.getItem(ELocalStorageKey.UserId);
  pageUrl: string;
  routerSubscription: Subscription;

  constructor(
    public modal: NgbModal,
    public router: Router,
    public authService: AuthService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.isUser();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pageUrl = event.urlAfterRedirects;
      }
    });
  }

  async isUser(): Promise<void> {
    await this.authService.currentSession();
  }

  openAuth(authType: string, clickText: string): void {
    let modalRef;
    if (window.innerWidth < 640) {
      modalRef = this.modal.open(AuthFormComponent, {
        size: 'sm',
        windowClass: 'modal-fullscreen'
      });
    } else {
      modalRef = this.modal.open(AuthFormComponent);
    }
    modalRef.componentInstance.isSignUp = authType === EAuthType.SignUp;
    let authButtonType;
    const pageName = this.getPageName(this.pageUrl);
    authType === EAuthType.Login
      ? (authButtonType = EButtonActionTypes.TriggerLogin)
      : (authButtonType = EButtonActionTypes.TriggerSignUp);
    this.analyticsService.click(clickText, '', pageName, authButtonType, '');
  }

  trackCTAEvent(clickText: string, url: string): void {
    const pageName = this.getPageName(this.pageUrl);
    this.analyticsService.click(clickText, url, pageName, EButtonActionTypes.HeaderNavigation, url);
  }

  getPageName(url: string): string {
    if (!url) {
      return 'Home';
    }
    // Implement logic to derive page name from URL
    const segments = url.split('/');
    return segments[segments.length - 1] || 'Home';
  }

  openProfile(event?: string): void {
    if (event === 'user-updated') {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.showDropdown = false;
    } else if (event === EProfileState.PROFILE_COMPLETE) {
      this.showProfileFlyout = true;
      this.showDropdown = !this.showDropdown;
      localStorage.setItem(ELocalStorageKey.ProfileCompleted, 'true');
      localStorage.removeItem(ELocalStorageKey.ProfileOpened);
    } else {
      this.showProfileFlyout = true;
      this.showDropdown = !this.showDropdown;
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
