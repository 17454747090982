import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { first, Subscription } from 'rxjs';

import { ConversationService } from '../../shared/services/conversation.service';
import { StatsigService } from '../../shared/services/statsig.service';
import { WebsocketService } from '../../shared/services/websocket.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { DiscoverService } from '../../shared/services/discover.service';
import { AuthService } from 'app/auth/auth.service';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';
import { MessageComponent } from '../../components/message/message.component';
import { CannedMessageComponent } from '../../components/canned-message/canned-message.component';
import { PaymentComponent } from '../../components/payment/payment.component';
import { SubscriptionModalComponent } from '../../components/subscription-modal/subscription-modal.component';
import { SubscriptionPaywallComponent } from '../../components/subscription-paywall/subscription-paywall.component';
import { LoadingComponent } from 'app/shared/components/loading/loading.component';
import { ConversationFeatureComponent } from '../../components/conversation-feature/conversation-feature.component';
import { FriendshipPanelComponent } from '../friendship-panel/friendship-panel.component';
import { ButtonComponent } from '../../shared/components/button/button.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';
import {
  IAiWebsocketResponse,
  IConversationHistoryResponse,
  IMessage,
  IMessageGroup
} from '../../interfaces/conversations.interfaces';
import { IPaymentResult, IPurchaseData } from '../../interfaces/payments.interfaces';
import {
  IAudioState,
  ICreatorDiscoverData,
  ICreatorsResponse
} from '../../interfaces/creator.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';
import { EChatStates, EFeatureEvent } from 'app/enums/conversation';
import { EPaymentType } from 'app/enums/payments.enums';
import { ELoadingTexts } from 'app/shared/enums/loading-texts.enums';
import { EFeatureGates } from 'app/shared/enums/feature-gates.enums';
import { EButtonSizes, EButtonTypes } from 'app/shared/enums/button.enums';

import { slideInOut } from 'app/shared/animations/slideInOut.animations';
import { SUPPORT_LINK } from '../../shared/const/app.constants';

@Component({
  selector: 'stxt-conversation-page',
  standalone: true,
  imports: [
    SvgIconComponent,
    MessageComponent,
    ReactiveFormsModule,
    CommonModule,
    NgbTooltipModule,
    CannedMessageComponent,
    PaymentComponent,
    SubscriptionPaywallComponent,
    SubscriptionModalComponent,
    LoadingComponent,
    ConversationFeatureComponent,
    FriendshipPanelComponent,
    ButtonComponent
  ],
  templateUrl: './conversation-page.component.html',
  styleUrl: './conversation-page.component.scss',
  providers: [NgbTooltipConfig],
  animations: [slideInOut]
})
export class ConversationPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  @ViewChild('inputMessage', { static: false }) inputMessageRef: ElementRef;
  websocketSubscription: Subscription;
  wsConnectionIdSubscription: Subscription;
  userStateSubscription: Subscription;
  backUrl: string = localStorage.getItem('backUrl');
  creatorId: string = this.route.snapshot.paramMap.get('chatId');
  creator: { creator_name: string; thumbnail_image: string };

  messages: IMessage[] = [];
  backIcon: ISvgConfig = { name: 'arrow_left', fill: ESvgTypes.Tonal };
  rightIcon: ISvgConfig = { name: 'arrow_right2', fill: ESvgTypes.None };
  questionIcon: ISvgConfig = { name: 'help-filled', fill: ESvgTypes.Tonal };
  responseIcon: ISvgConfig = { name: 'suggest', fill: ESvgTypes.None };
  tipIcon: ISvgConfig = { name: 'tip', fill: ESvgTypes.None };
  sendIcon: ISvgConfig = { name: 'interactive', fill: ESvgTypes.None };
  friendshipButtonConfig: IButtonConfig = {
    fill: EButtonTypes.Empty,
    buttonSize: EButtonSizes.Default
  };
  friendshipIcon: ISvgConfig = { name: 'favorite-filled', fill: ESvgTypes.Tonal };
  historyMessages: IMessageGroup[] = [];
  purchaseData: IPurchaseData;
  creatorData: ICreatorDiscoverData;
  mediaTags: string[] = [];

  isPayment: boolean = false;
  isCannedResponses: boolean = false;
  isKeyboardVisible: boolean = false;
  isLoading: boolean;
  isSubscription: boolean = false;
  isRequestPhotosEnabled: boolean = false;
  isFriendshipEnabled: boolean = false;
  isCreatorDataExists: boolean = false;
  isRequestPhoto: boolean = false;
  isUserLoaded: boolean = false;
  previewMode: boolean = false;
  isTyping: boolean = false;
  firstRender: boolean = true;
  userScrolledUp = false;
  isFriendshipOpen: boolean = true;
  showFriendshipText: boolean = true;
  voiceSupported: boolean = false;
  activeIndex: number;
  headerHeight: number;
  inputMessageHeight: number = 24;
  previousScrollHeight: number = 0;
  chatBodyHeight: number = 100;
  windowHeight: number = window.innerHeight;
  inputMessage: HTMLSpanElement;
  userId: string = localStorage.getItem('userId');
  paymentType: string;
  wsConnectionId: string;
  getHelpLink: string = SUPPORT_LINK;
  keyboardHeight: number;
  cannedMessages: string[];
  offset: string;
  friendsSince: Date;
  selectedMessageAudio: string;

  protected readonly EPaymentType = EPaymentType;
  protected readonly ELoadingTexts = ELoadingTexts;
  protected readonly window = window;

  constructor(
    public modal: NgbModal,
    public router: Router,
    private statsigService: StatsigService,
    readonly analyticsService: AnalyticsService,
    readonly authService: AuthService,
    readonly conversationService: ConversationService,
    readonly cdr: ChangeDetectorRef,
    readonly discoverService: DiscoverService,
    readonly route: ActivatedRoute,
    readonly renderer: Renderer2,
    readonly websocketService: WebsocketService
  ) {}

  ngOnInit(): void {
    this.headerHeight = window.innerWidth >= 640 ? 104 : 84;
    this.checkLoading();
    this.checkCreator();
    this.setFriendshipOpenState();
  }

  initChat(): void {
    this.getConversationMessages();
    setTimeout(() => this.scrollToBottom(), 0);
  }

  checkLoading(): void {
    this.userStateSubscription = this.authService.userLoaded$.subscribe({
      next: (state: boolean) => {
        if (!state) return;
        this.initChat();
      }
    });
  }

  async checkStatsig(): Promise<void> {
    await this.statsigService.initializeStatsig(this.userId);
    this.isRequestPhotosEnabled = await this.statsigService.checkFeatureGate(
      EFeatureGates.REQUEST_PHOTOS
    );
    this.isFriendshipEnabled = await this.statsigService.checkFeatureGate(
      EFeatureGates.FRIENDSHIP_TIMELINE
    );
    this.calculateChatBodyHeight();
    this.scrollToBottom();
  }

  checkCreator(): void {
    this.discoverService
      .getDiscoverCreatorData(this.creatorId)
      .pipe(first())
      .subscribe({
        next: (res: ICreatorsResponse) => {
          const creator = res.creators[0];
          this.creator = { creator_name: creator.name, thumbnail_image: creator.image };
          this.creatorData = creator;
          this.isCreatorDataExists = true;
          localStorage.setItem('creatorInfo', JSON.stringify(this.creator));
        },
        error: err => {
          console.log(err);
        }
      });
  }

  ngAfterViewInit(): void {
    this.calculateChatBodyHeight();
    this.inputMessage = this.inputMessageRef.nativeElement;
    this.scrollToBottom();
  }

  getConversationStatus(): void {
    this.conversationService
      .getConversationStatus(this.creatorId)
      .pipe(first())
      .subscribe({
        next: res => {
          this.isSubscription = res.show_subscription_paywall;
          this.voiceSupported = res.voice_supported;
          this.friendsSince = res.created_at;
          this.calculateChatBodyHeight();
        },
        error: err => console.log(err)
      });
  }

  checkLastMessage(message: IMessage): void {
    if (!message.payload.canned_responses?.length) return;
    this.isCannedResponses = true;
    this.cannedMessages = message.payload.canned_responses;
    this.calculateChatBodyHeight();
  }

  initWebsocketSubscription(): void {
    // Subscribe to WebSocket messages after connection is established
    this.websocketSubscription = this.websocketService.onMessage().subscribe(
      (message: IAiWebsocketResponse) => {
        if (message !== null) {
          if (message.category === 'event' && message.payload.category === 'command') {
            this.checkWSEvent(message.payload.event);
            return;
          } else if (
            message.category === 'event' &&
            message.payload.event === 'subscription_completed'
          ) {
            this.isSubscription = false;
            return;
          } else if (message.payload.canned_responses?.length) {
            this.isCannedResponses = true;
            this.cannedMessages = message.payload.canned_responses;
            this.calculateChatBodyHeight();
          } else {
            this.isCannedResponses = false;
          }
          this.updateMessages(message);
        }
      },
      error => {
        console.error('WebSocket error:', error);
      }
    );
    this.trackEvent();
  }

  getConversationMessages(): void {
    this.conversationService
      .getConversationHistory(this.creatorId)
      .pipe(first())
      .subscribe({
        next: (res: IConversationHistoryResponse) => {
          if (res.messages?.length) {
            this.historyMessages = this.conversationService.groupMessagesByDate(
              res.messages.reverse()
            );
            this.checkLastMessage(res.messages.slice(-1)[0]);
            this.offset = res.offset;
            this.scrollToBottom();
            this.calculateChatBodyHeight();
          }
          this.completeChatInitialization(); // Call only after successful next block
        },
        error: err => {
          console.log(err);
          this.completeChatInitialization(); // Handle error and complete chat initialization
        }
      });
  }

  completeChatInitialization(): void {
    this.firstRender = false;
    const sessionToken: string = localStorage.getItem(`accessToken`);
    this.websocketService.connect(sessionToken, this.creatorId);
    this.getConversationStatus();
    this.initWebsocketSubscription();
    this.getWSConnectionId();
    this.checkStatsig();
    this.calculateChatBodyHeight();
    this.isUserLoaded = true;
  }

  getWSConnectionId(): void {
    this.wsConnectionIdSubscription = this.websocketService.wsConnectionId$.subscribe(
      (id: string) => {
        this.wsConnectionId = id;
      }
    );
  }

  checkWSEvent(event: string): void {
    switch (event) {
      case 'typing_indicator_on':
        this.isTyping = true;
        this.scrollToBottom();
        break;
      case 'typing_indicator_off':
        this.isTyping = false;
        break;
      case 'subscription_paywall_on':
        this.isSubscription = true;
        this.calculateChatBodyHeight();
        this.analyticsService.subscribeMessageSent(
          this.userId,
          EChatStates.WAITING_FOR_SUBSCRIPTION,
          this.creatorId
        );
        break;
    }
  }

  trackEvent(): void {
    const chatState = this.isSubscription
      ? EChatStates.WAITING_FOR_SUBSCRIPTION
      : EChatStates.FREEFLOW;
    this.analyticsService.chatInitiated(
      this.userId,
      this.creator?.creator_name,
      chatState,
      `${this.creatorId}-${this.userId}`,
      this.creatorId
    );
  }

  handleFeatureEvent(event: { eventName: string; selectedTag?: string }): void {
    switch (event.eventName) {
      case EFeatureEvent.REQUEST_MEDIA_OPEN:
        this.isRequestPhoto = true;
        break;
      case EFeatureEvent.REQUEST_MEDIA_CLOSE:
        this.isRequestPhoto = false;
        break;
      case EFeatureEvent.HandleSendTag:
        this.userScrolledUp = false;
        this.isRequestPhoto = false;
        this.websocketService.sendAction('MEDIA_REQUEST', {
          creator_id: this.creatorId,
          tags: [event?.selectedTag]
        });
    }
    this.scrollToBottom();
  }

  updateMessages(message: IAiWebsocketResponse) {
    const aiResponse = {
      from_stxt: true,
      category: message.category,
      payload: message.payload,
      timestamp: message.timestamp
    };
    this.messages = [...this.messages, aiResponse];
    this.scrollToBottom();
  }

  onScroll($event: Event): void {
    const elem = $event.srcElement as HTMLElement;
    this.userScrolledUp = elem.scrollTop < this.previousScrollHeight;
    this.previousScrollHeight = elem.scrollTop;
    if (elem.scrollTop < 1) {
      elem.scrollTo(0, 1);
    }

    if (this.isLoading) return;

    if (elem.scrollTop < 50) {
      this.isLoading = true;
      this.uploadMoreMessages();
    }
  }

  adjustScroll(): void {
    if (!this.userScrolledUp && this.messageContainer) {
      this.messageContainer.nativeElement.scrollTop =
        this.messageContainer.nativeElement.scrollHeight;
    }
  }

  uploadMoreMessages(): void {
    this.isLoading = true;

    if (!this.offset?.length || this.firstRender) return;

    this.conversationService
      .getConversationHistory(this.creatorId, this.offset)
      .pipe(first())
      .subscribe((res: IConversationHistoryResponse) => {
        if (res.messages) {
          const reversedMessages = res.messages.reverse();
          const historyMessages = this.conversationService.groupMessagesByDate(reversedMessages);
          historyMessages.reverse().forEach((messagePack: IMessageGroup) => {
            this.historyMessages.unshift(messagePack);
          });
          this.offset = res.offset;
          this.isLoading = false;
        }
      });
  }

  /**
   * Scrolls to the bottom of a container element, ensuring the most recent content is visible.
   * If the container element exists, it sets a timeout to allow for asynchronous updates
   * before scrolling to the bottom of the container.
   */
  scrollToBottom() {
    this.cdr.detectChanges(); // Manually trigger change detection
    setTimeout(() => {
      // Ensure the adjustment occurs after the DOM updates
      this.adjustScroll();
    }, 0);
  }

  setActive(index: number, message: IMessage) {
    this.activeIndex = this.activeIndex === message.timestamp ? null : message.timestamp;
  }

  trackByFn(index: number, item: IMessage): number {
    return item.timestamp; // or another unique identifier
  }

  /**
   * Sends a message to a chat interface or similar component.
   * @param value The string value of the message to be sent.
   */
  sendMessage(value: string): void {
    this.userScrolledUp = false;
    this.isCannedResponses = false;
    if (!this.isContentValid(value)) {
      return;
    }
    if (this.isSubscription) {
      this.openSubscriptionModal();
    } else {
      if (!value.length) return;

      this.isCannedResponses = false;
      this.inputMessage.innerHTML = '';
      const message: IMessage = {
        from_stxt: false,
        payload: { message: value },
        timestamp: Date.now()
      };

      this.messages = [...this.messages, message];
      this.scrollToBottom();
      this.websocketService.sendAction('MESSAGE', { message: value, creator_id: this.creatorId });
      this.scrollToBottom();
      this.inputMessageHeight = 24;
    }
  }

  calculateChatBodyHeight(): void {
    const footerHeight = this.getFooterHeight();

    const headerHeight: number = window.innerWidth >= 640 ? 104 : 84;

    let bodyHeight = this.windowHeight - headerHeight - this.inputMessageHeight - footerHeight;

    if (this.isKeyboardVisible && this.keyboardHeight) {
      bodyHeight -= this.keyboardHeight;
    }
    this.chatBodyHeight = bodyHeight;
    this.scrollToBottom(); // Ensure scrolling to bottom after height adjustment
  }

  getFooterHeight(): number {
    let footerHeight = 20;
    if (this.isSubscription) {
      footerHeight = 125;
    } else if (this.cannedMessages) {
      footerHeight = 95;
    } else if (this.isRequestPhotosEnabled) {
      footerHeight = 65;
    }
    return footerHeight;
  }

  closePayment(event: IPaymentResult): void {
    if (event.status === 'success' && event.paymentType === EPaymentType.Subscription) {
      this.isSubscription = false;
      this.calculateChatBodyHeight();
    } else if (event.status === 'success' && event.paymentType === EPaymentType.Purchase) {
      this.updateMedia();
      this.calculateChatBodyHeight();
    }
    this.isPayment = false;
  }

  updateMedia(): void {
    this.conversationService
      .getConversationMessage(this.creatorId, this.purchaseData.message_timestamp)
      .pipe(first())
      .subscribe({
        next: (res: IConversationHistoryResponse) => {
          const updatedMessage = {
            ...res.messages[0],
            timestamp: Number(res.messages[0].timestamp)
          };

          this.messages = this.messages.map(msg => {
            return msg.timestamp === updatedMessage.timestamp ? { ...updatedMessage } : { ...msg };
          });
          this.historyMessages = this.historyMessages.map(group => ({
            ...group,
            messages: group.messages.map(msg =>
              Number(msg.timestamp) === updatedMessage.timestamp
                ? { ...updatedMessage }
                : { ...msg }
            )
          }));
          this.cdr.detectChanges();
        },
        error: (err: Error) => {
          console.error('Error updating media:', err);
        }
      });
  }

  onMessageInputChange(event: KeyboardEvent): void {
    this.calculateChatBodyHeight();
    this.checkEnterEvent(event);
    if (!event.shiftKey && event.key === 'Enter' && this.inputMessage.innerHTML.length > 0) {
      this.sendMessage(this.inputMessage.innerHTML);
      this.cleanUpEmptyContent();
    } else {
      this.inputMessage.style.height = 'auto';
      // Calculate new height based on scroll height;
      const newHeight = Math.max(this.inputMessage.scrollHeight, this.inputMessageHeight);
      // Only update inputMessage height if the height has changed
      if (this.inputMessageHeight !== newHeight) {
        this.inputMessageHeight = newHeight;
        this.inputMessage.style.height = newHeight + 'px';
      }

      requestAnimationFrame(() => {
        const newHeight = this.inputMessage.scrollHeight;

        // Update inputMessage height only if the content is not empty and the height has changed
        if (this.inputMessage.innerHTML.trim() !== '') {
          this.inputMessageHeight = newHeight;
          this.inputMessage.style.height = newHeight + 'px';
        }
        // Update chat body height
        this.updateChatBodyHeight();
        this.scrollToBottom();
      });
    }
  }

  checkEnterEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      const content = this.inputMessage.innerHTML.trim();
      if (!content) return;
      this.sendMessage(content);
      this.renderer.setProperty(this.inputMessage, 'innerHTML', '');
    }
  }

  cleanUpEmptyContent(): void {
    const content = this.inputMessage.innerText.trim();
    if (content === '' || this.inputMessage.innerHTML.trim() === '<br>') {
      this.renderer.setProperty(this.inputMessage, 'innerHTML', '');
    }
  }

  openSubscriptionModal(): void {
    const modalRef = this.modal.open(SubscriptionModalComponent);
    modalRef.componentInstance.creatorData = this.creator;
    modalRef.componentInstance.subscriptionPrice = this.creatorData.sub_plan.price;
    modalRef.closed.subscribe({
      next: res => {
        if (res === 'payment') {
          this.paymentType = EPaymentType.Subscription;
          this.isPayment = true;
        }
      },
      error: err => {
        console.log('Error', err);
      }
    });
  }

  openPayment(paymentType: string, message?: IMessage): void {
    if (message) {
      this.purchaseData = { message_timestamp: message.timestamp, price: message.payload.price };
    }
    this.paymentType = paymentType;
    this.isPayment = true;
  }

  /**
   * Handles the paste event on the "contenteditable" element.
   * Prevents pasting images or rich text, allowing only plain text to be inserted.
   * @param event The ClipboardEvent triggered by the paste action.
   */
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const text = clipboardData.getData('text/plain');

    // Insert text at the current caret position
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      selection.deleteFromDocument();
      const range = selection.getRangeAt(0);
      range.insertNode(document.createTextNode(text));

      // Move the caret to the end of the inserted text
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // Re-focus the contenteditable div
    const target = event.target as HTMLElement;
    if (target && target.isContentEditable) {
      setTimeout(() => target.focus(), 0);
    }
  }

  isContentValid(content: string): boolean {
    // Remove all <br> tags and check if there is any non-whitespace content left
    const strippedContent = content.replace(/<br\s*\/?>/gi, '').trim();
    return strippedContent !== '';
  }

  updateChatBodyHeight(): void {
    const chatBody = this.messageContainer.nativeElement;
    chatBody.style.height = this.chatBodyHeight + 'px';
  }

  toggleFriendshipDrawer(): void {
    this.isFriendshipOpen = !this.isFriendshipOpen;
  }

  setFriendshipOpenState(): void {
    this.isFriendshipOpen = window.innerWidth > 926;
    this.showFriendshipText = window.innerWidth > 480;
    this.cdr.detectChanges();
  }

  trackSelectedVoice(audioState: IAudioState): void {
    if (audioState.isPlaying) {
      this.selectedMessageAudio = audioState.id as string;
    } else if (this.selectedMessageAudio === audioState.id) {
      this.selectedMessageAudio = null;
    }
  }

  openCreatorProfile(): void {
    this.router.navigateByUrl(`/creator/${this.creatorId}`);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (!target.classList.contains('message') && !target.closest('.message')) {
      this.activeIndex = null;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowHeight = window.innerHeight;
    this.isFriendshipOpen = window.innerWidth > 926;
    this.showFriendshipText = window.innerWidth > 480;
    this.calculateChatBodyHeight(); // Recalculate body height on window resize
  }

  ngOnDestroy(): void {
    this.websocketService.closeConnection();
    this.wsConnectionIdSubscription?.unsubscribe();
    this.userStateSubscription?.unsubscribe();
    this.websocketSubscription?.unsubscribe();
  }
}
